import { useLoginForm } from 'modules/auth/hooks/useLoginForm'
import { PropsWithChildren } from 'react'
import { FormProvider } from 'react-hook-form'
interface Props {
  className?: string
}

export const LoginFormProvider = ({
  className,
  children,
}: PropsWithChildren<Props>) => {
  const form = useLoginForm()

  return (
    <FormProvider {...form}>
      <div className={className}>{children}</div>
    </FormProvider>
  )
}
