import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren, ReactNode } from 'react'
import { Trans } from 'react-i18next'

export type CalloutProps = PropsWithChildren & {
  backgroundColor?: 'transparent' | 'primary'
  className?: string
  text: string
  title: string
  withShadow?: boolean
  footer?: ReactNode
}

export const Callout = ({
  backgroundColor = 'transparent',
  className,
  children,
  text,
  title,
  withShadow = false,
  footer = null,
}: CalloutProps) => (
  <div
    className={mergeClasses(
      'flex h-full items-stretch justify-between rounded-2xl text-left',
      {
        'border shadow-md': withShadow,
        'border-info-100 bg-transparent': backgroundColor === 'transparent',
        'border-primary-100 bg-primary-90': backgroundColor === 'primary',
      },
      className
    )}
  >
    <div
      className={mergeClasses('flex flex-col space-y-2 p-4', {
        'pr-0': children,
      })}
    >
      <Trans parent="h3">{title}</Trans>
      <Trans parent="p" className="text-sm">
        {text}
      </Trans>

      {footer}
    </div>
    {children && <div className="flex shrink-0 items-center">{children}</div>}
  </div>
)
