import { ButtonCallout } from 'modules/common/components/Callout/ButtonCallout/ButtonCallout'
import { Illustration } from 'modules/common/components/Illustrations/Illustration'
import { useCountryExternalLinks } from 'modules/common/hooks/useCountryExternalLinks'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const LoginDiscoverCallout = () => {
  const { t } = useTranslation('auth-components')
  const externalLinks = useCountryExternalLinks()

  if (!externalLinks.discoverManagementToolsOffer) {
    return null
  }

  return (
    <ButtonCallout
      backgroundColor="primary"
      title={t('loginDiscoverCallout.title')}
      text={t('loginDiscoverCallout.text')}
      href={externalLinks.discoverManagementToolsOffer}
      buttonLabel={t('loginDiscoverCallout.cta')}
    >
      <Illustration name="invoicing-tool" size="l" />
    </ButtonCallout>
  )
}
