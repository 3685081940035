import { EnumSignupType } from './../../types/signup'
import { useBanner } from 'modules/common/hooks/useBanner'
import { EnumBannerKey } from 'modules/common/types/banner'

interface Props {
  signupType: EnumSignupType
}

export const useLoginBanner = ({ signupType }: Props) => {
  return useBanner({
    bannerKey: EnumBannerKey.LOGIN_BANNER,
    displayCondition: signupType !== EnumSignupType.STRICTLY_MANAGEMENT_TOOLS,
  })
}
