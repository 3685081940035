import { buildTimeConfig } from 'config'
import { LoginBanner } from 'modules/auth/components/LoginBanner/LoginBanner'
import { useLoginBanner } from 'modules/auth/components/LoginBanner/useLoginBanner'
import { LoginLocaleSwitcher } from 'modules/auth/components/LoginLocaleSwitcher/LoginLocaleSwitcher'
import { EnumSignupType } from 'modules/auth/types/signup'
import { Image } from 'modules/common/components/Image/Image'
import Head from 'next/head'
import { PropsWithChildren } from 'react'
import { FeedbackAlert } from '../FeedbackAlert/FeedbackAlert'
import { Header } from '../Header/Header'

interface Props {
  imagePath?: string
  signupType?: EnumSignupType
}

export const LayoutLogin = ({
  children,
  imagePath = '/assets/img/login-screen.png',
  signupType = EnumSignupType.BANK,
}: PropsWithChildren<Props>) => {
  const { isBannerToBeDisplayed, hideBanner } = useLoginBanner({ signupType })

  return (
    <>
      <div className="flex min-h-screen flex-col bg-white">
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>
        <Header
          rightItem={
            buildTimeConfig.locales.length > 1 ? (
              <LoginLocaleSwitcher locales={buildTimeConfig.locales} />
            ) : null
          }
        />
        <div className="relative flex flex-1 justify-center">
          <div className="flex w-full max-w-lg flex-shrink-0 flex-col items-center justify-center px-8 py-8 lg:px-24">
            {children}
          </div>
          <FeedbackAlert />
          <div className="hidden flex-grow flex-col items-center justify-between gap-6 bg-gradient-to-tr from-primary-90 to-primary-200 p-6 lg:flex">
            {isBannerToBeDisplayed && <LoginBanner onClose={hideBanner} />}
            <div className="flex flex-1 items-center">
              <Image
                alt="login-img"
                src={imagePath}
                width="488"
                height="488"
                priority
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LayoutLogin
