import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { EnumRoutes } from '../routes'

export const usePrefetch = (routes: EnumRoutes[]) => {
  const router = useRouter()

  useEffect(() => {
    routes?.forEach((route) => router.prefetch(route))
  }, [router, routes])
}
