import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { rEmail } from 'modules/common/lib/validation/rules'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useLoginContextState } from '../contexts/LoginContext'

export interface LoginFormData {
  email: string
  password: string
}

export const useLoginForm = () => {
  const { t } = useTranslation('auth-components')
  const {
    user: { email = '' },
  } = useLoginContextState()

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('fillLoginForm.email.error.required'))
      .matches(rEmail, t('fillLoginForm.email.error.format')),
    password: yup.string().required(t('fillLoginForm.password.error.required')),
  })

  return useForm<LoginFormData>({
    mode: 'onTouched',
    defaultValues: {
      email,
    },
    resolver: yupResolver(schema),
  })
}
