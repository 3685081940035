import { EnumUserLocale } from 'modules/common/types/locale'
import { IconFlagFrance, IconFlagItaly } from '../icons'

interface Props {
  className?: string
  locale: EnumUserLocale
}

export const IconCountryFlag = ({ className, locale }: Props) => {
  switch (locale) {
    case EnumUserLocale.FR: {
      return <IconFlagFrance className={className} />
    }
    case EnumUserLocale.IT: {
      return <IconFlagItaly className={className} />
    }
  }
}
