import { sanitizeUrl } from '@braintree/sanitize-url'
import { usePrefetch } from 'modules/common/hooks/usePrefetch'
import { EnumRoutes } from 'modules/common/routes'
import { clearAllDocumentCreationForms } from 'modules/invoicing/lib/storage/documentCreationForm'
import { useRouter } from 'next/router'

import { useLoginContextDispatch } from '../contexts/LoginContext'
import { LoginFormData } from '../hooks/useLoginForm'
import { usePostLoginUserMutation } from '../mutations/usePostLoginUserMutation'
import { EnumLoginErrors } from '../types/auth0'

export const useLoginFlow = () => {
  usePrefetch([
    EnumRoutes.HOME,
    EnumRoutes.KYC_ROOT,
    EnumRoutes.EMAIL_VERIFICATION,
    EnumRoutes.ACCOUNT_BLOCK,
    EnumRoutes.PASSWORD_CHANGE_REQUEST,
  ])
  const router = useRouter()

  const dispatch = useLoginContextDispatch()
  const redirectURL = sanitizeUrl(
    router.query.redirectURL as string | undefined
  )

  const {
    mutate: loginUserMutate,
    isError: isLoginError,
    isPending: isLoginPending,
  } = usePostLoginUserMutation({
    mutationOptions: {
      onSuccess: () => {
        // If no redirectURL provided, we force page reload to handle redirection via NotAuthenticatedPageGuard
        const isInternalRedirectUrl = Object.values(EnumRoutes).some((route) =>
          redirectURL?.startsWith(route)
        )
        router.replace(isInternalRedirectUrl ? redirectURL : EnumRoutes.LOGIN)
      },
      onError: (loginError) => {
        if (
          loginError?.response?.data?.error ===
          EnumLoginErrors.TOO_MANY_ATTEMPTS
        ) {
          router.replace(EnumRoutes.ACCOUNT_BLOCK)
        }
      },
    },
  })

  const forgotPasswordRedirect = (userEmail: string) => {
    dispatch({
      type: 'setUser',
      payload: {
        email: userEmail || '',
      },
    })
    router.push(EnumRoutes.PASSWORD_CHANGE_REQUEST)
  }

  const loginUser = (data: LoginFormData) => {
    clearAllDocumentCreationForms() // Clear all documents creation form from local storage

    dispatch({
      type: 'setUser',
      payload: {
        email: data.email,
      },
    })
    loginUserMutate(data)
  }

  return {
    isLoginPending,
    isLoginError,
    loginUser,
    forgotPasswordRedirect,
  }
}
