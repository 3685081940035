import { EnumSignupType } from 'modules/auth/types/signup'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useCountryExternalLinks } from 'modules/common/hooks/useCountryExternalLinks'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { LoginDiscoverCallout } from './LoginDiscoverCallout/LoginDiscoverCallout'
import { LoginForm } from './LoginForm/LoginForm'
import { LoginFormProvider } from './LoginForm/LoginFormProvider'

interface Props {
  signupType: EnumSignupType
}

export const LoginContainer = ({ signupType }: Props) => {
  const { t } = useTranslation('auth-components')
  const externalLinks = useCountryExternalLinks()

  const signupUrl =
    signupType === EnumSignupType.STRICTLY_MANAGEMENT_TOOLS
      ? EnumRoutes.MANAGEMENT_TOOLS_SIGNUP
      : EnumRoutes.SIGNUP

  return (
    <div className="flex h-full w-full flex-1 flex-col">
      <div className="flex flex-1 flex-col items-center justify-center">
        <h2 className="pb-10">{t('loginContainer.title')}</h2>
        <div className="flex w-full flex-col gap-8 divide-y divide-info-200">
          <LoginFormProvider>
            <LoginForm />
          </LoginFormProvider>
          <div className="flex flex-col gap-8 pt-8">
            <div className="flex flex-col items-center ">
              <p className="pb-4 text-sm font-bold text-info-600">
                {t('loginContainer.signup.label')}
              </p>
              <Button
                variant="secondary"
                color="default"
                className="self-stretch"
                href={signupUrl}
              >
                {t('loginContainer.signup.cta')}
              </Button>
            </div>
            {signupType === EnumSignupType.STRICTLY_MANAGEMENT_TOOLS && (
              <LoginDiscoverCallout />
            )}
          </div>
        </div>
      </div>
      <Button
        variant="none"
        color="default"
        href={externalLinks.legalLink}
        className="mt-8"
      >
        {t('loginContainer.legalLink')}
      </Button>
    </div>
  )
}
