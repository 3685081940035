import { EnumCookieKeys, FALLBACK_LOCALE } from '_constants'
import Cookies from 'js-cookie'
import { IconCountryFlag } from 'modules/common/components/_icons/IconCountryFlag/IconCountryFlag'
import { IconExpandMore } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { DropdownMenu } from 'modules/common/components/_ui/DropdownMenu/DropdownMenu'
import { DropdownMenuItem } from 'modules/common/components/_ui/DropdownMenu/DropdownMenuItem'
import { useDropdownMenu } from 'modules/common/hooks/useDropdownMenu'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumUserLocale } from 'modules/common/types/locale'
import { useRouter } from 'next/router'
import { useState } from 'react'

interface Props {
  locales: EnumUserLocale[]
}

export const LoginLocaleSwitcher = ({ locales }: Props) => {
  const router = useRouter()

  const [currentLocale, setCurrentLocale] = useState(
    (router.locale as EnumUserLocale) || FALLBACK_LOCALE
  )

  const { t } = useTranslation('auth-components')

  const {
    registerDropdownButton,
    registerDropdownMenu,
    registerDropdownMenuItem,
  } = useDropdownMenu()

  const switchLocale = (locale: EnumUserLocale) => {
    setCurrentLocale(locale)
    router.replace(router.pathname, router.asPath, { locale })
    Cookies.set(EnumCookieKeys.NEXT_LOCALE, locale)
  }

  return (
    <>
      <Button
        className="flex items-center gap-2"
        variant="none"
        icon={<IconExpandMore className="h-6 w-6" />}
        iconPosition="right"
        {...registerDropdownButton()}
      >
        <IconCountryFlag locale={currentLocale} />
        {t('localeSwitcher.label', { context: currentLocale })}
      </Button>
      <DropdownMenu {...registerDropdownMenu()}>
        {locales.map((locale) => (
          <DropdownMenuItem
            key={locale}
            {...registerDropdownMenuItem({
              onClick: () => switchLocale(locale),
            })}
          >
            <IconCountryFlag locale={locale} />
            {t('localeSwitcher.label', { context: locale })}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </>
  )
}
