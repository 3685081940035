import { useEffect, useState } from 'react'
import {
  FieldNamesMarkedBoolean,
  FieldPath,
  FieldValues,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form'
import { TrackingEvent } from '../lib/amplitude/events'
import { trackEvent } from '../lib/amplitude/trackEvent'

interface Props<TFieldValues extends FieldValues> {
  name: FieldPath<TFieldValues>
  event?: TrackingEvent
  // Provide form if there is no FormContextProvider
  form?: UseFormReturn<TFieldValues>
}
export function useTrackDirtyField<TFieldValues extends FieldValues>({
  name,
  event,
  form,
}: Props<TFieldValues>) {
  const formContext = useFormContext<TFieldValues>()
  const { dirtyFields } = form?.formState || formContext.formState
  const isDirty =
    !!dirtyFields[name as keyof FieldNamesMarkedBoolean<TFieldValues>]

  const [isTracked, setIsTracked] = useState(isDirty)

  useEffect(() => {
    if (event && !isTracked && isDirty) {
      setIsTracked(true)
      trackEvent({
        eventType: event,
      })
    }
  }, [isDirty, isTracked, event])
}
