import { useLoginFlow } from 'modules/auth/flows/useLoginFlow'
import { LoginFormData } from 'modules/auth/hooks/useLoginForm'
import { EnumAuthTrackingEvents } from 'modules/auth/lib/tracking/events'
import { IconEmail, IconLocker } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { PasswordTextField } from 'modules/common/components/_ui/FormControl/PasswordTextField/PasswordTextField'
import { TextField } from 'modules/common/components/_ui/FormControl/TextField/TextField'
import { Message } from 'modules/common/components/_ui/Message/Message'
import { useTrackDirtyField } from 'modules/common/hooks/useTrackDirtyField'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useFormContext } from 'react-hook-form'

export const LoginForm = () => {
  const { t } = useTranslation('auth-components')

  const { isLoginPending, isLoginError, loginUser, forgotPasswordRedirect } =
    useLoginFlow()

  const { control, handleSubmit, watch, formState, register } =
    useFormContext<LoginFormData>()
  const { errors } = formState

  useTrackDirtyField<LoginFormData>({
    name: 'email',
    event: EnumAuthTrackingEvents.LOGIN_EMAIL_FORM_STARTED,
  })

  useTrackDirtyField<LoginFormData>({
    name: 'password',
    event: EnumAuthTrackingEvents.LOGIN_PASSWORD_FORM_STARTED,
  })

  return (
    <form onSubmit={handleSubmit((data) => loginUser(data))} method="post">
      <div className="flex flex-col gap-4">
        <TextField
          id="email"
          {...register('email')}
          label={t('fillLoginForm.email.label')}
          leftDecorator={<IconEmail />}
          control={control}
          error={errors.email}
          data-cy="login-email-field"
          autoComplete="username"
        />

        <PasswordTextField
          id="password"
          {...register('password')}
          label={t('fillLoginForm.password.label')}
          leftDecorator={<IconLocker />}
          control={control}
          error={errors.password}
          data-cy="login-password-field"
        />
        {isLoginError && (
          <Message
            title={t('fillLoginForm.errors.login.title')}
            content={t('fillLoginForm.errors.login.content')}
            type="error"
          />
        )}
        <Button
          variant="tertiary"
          color="default"
          type="button"
          onClick={() => forgotPasswordRedirect(watch('email'))}
        >
          {t('fillLoginForm.forgotPassword')}
        </Button>
        <Button
          variant="primary"
          color="default"
          isLoading={isLoginPending}
          className="w-full"
          type="submit"
          data-cy="login-submit-button"
        >
          {t('fillLoginForm.submitLabel')}
        </Button>
      </div>
    </form>
  )
}
