import { Link } from 'modules/common/components/Link/Link'
import { IconCross, IconDanger } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useCountryExternalLinks } from 'modules/common/hooks/useCountryExternalLinks'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Trans } from 'next-i18next'

interface Props {
  onClose: () => void
}
export const LoginBanner = ({ onClose }: Props) => {
  const { t } = useTranslation('auth-components')

  const externalLinks = useCountryExternalLinks()

  return (
    <div className="relative flex gap-3 rounded-lg border border-danger-100 bg-white p-4 shadow-md">
      <div className="flex-shrink-0">
        <IconDanger className="text-danger" />
      </div>
      <div className="flex flex-1 flex-col gap-3">
        <p className="pr-4 font-bold">{t('loginBanner.title')}</p>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-bold">{t('loginBanner.subtitle')}</p>
          <p className="text-sm">
            <Trans
              parent="p"
              className="text-sm"
              components={{
                mailtoComponent: (
                  <Link
                    href={`mailto:${externalLinks.securityEmail}`}
                    className="font-bold text-primary"
                    key="security-email"
                  />
                ),
                linkComponent: (
                  <Link
                    href={externalLinks.bankScamPreventionLink}
                    className="font-bold text-primary underline"
                  />
                ),
              }}
            >
              {t('loginBanner.text', { email: externalLinks.securityEmail })}
            </Trans>
          </p>
        </div>
      </div>
      <Button
        type="button"
        variant="none"
        onClick={onClose}
        className="ml-3 h-6 w-6"
        icon={<IconCross />}
      />
    </div>
  )
}
