import { Button } from '../../_ui/Button/Button'
import { Callout, CalloutProps } from '../Callout'

type Props = CalloutProps & {
  href: string
  target?: string
  buttonLabel: string
}

export const ButtonCallout = ({
  href,
  buttonLabel,
  target,
  ...calloutProps
}: Props) => (
  <Callout
    {...calloutProps}
    footer={
      <Button
        variant="primary"
        size="small"
        color="default"
        href={href}
        target={target}
        className="self-start"
      >
        {buttonLabel}
      </Button>
    }
  ></Callout>
)
